import { gql } from 'apollo-boost';

export const showUser = gql`
  query admin {
    admin {
      id
      email
      roles
      master
      notificationByEmail
    }
  }
`;

export const createUser = gql`
  mutation createAdmin(
    $email: String!
    $password: String!
    $roles: String!
  ) {
    createAdmin(
      email: $email,
      password: $password,
      roles: $roles
    ) {
      id
      email
      roles
    }
  }
`;

export const updateUser = gql`
  mutation updateAdmin(
    $email: String!
    $password: String!
    $roles: String!
    $id: ID!
  ) {
    updateAdmin(
      email: $email,
      password: $password,
      roles: $roles,
      id: $id
    ) {
      id
      email
      roles
    }
  }
`;

export const updateAccount = gql`
  mutation updateAccountAdmin(
    $id: ID!,
    $email: String!
    $password: String!
    $newPassword: String
    $newPasswordConfirmation: String
    $notificationByEmail: String!
  ) {
    updateAccountAdmin(
      id: $id,
      email: $email,
      password: $password,
      newPassword: $newPassword,
      newPasswordConfirmation: $newPasswordConfirmation,
      notificationByEmail: $notificationByEmail
    ) {
      id
      email
      token
      roles
      notificationByEmail
    }
  }
`;

export const signinUser = gql`
  mutation signinAdmin(
    $email: String!,
    $password: String!,
    $supplierId: ID
  ) {
    signinAdmin(email: $email, password: $password supplierId: $supplierId) {
      id
      email
      token
      roles
    }
  }
`;

export const forgotPasswordUser = gql`
  mutation forgotPasswordAdmin($email: String!) {
    forgotPasswordAdmin(email: $email) {
      email
    }
  }
`;

export const resetPasswordUser = gql`
  mutation resetPasswordAdmin(
    $token: String!,
    $email: String!,
    $password: String!
  ) {
    resetPasswordAdmin(
      token: $token,
      email: $email,
      password: $password
    ) {
      email
    }
  }
`;

export const listUsers = gql`
  query ShowSupplier(
    $supplierId: ID!,
  ) {
    supplier(
      id: $supplierId,
    ) {
      id
      admins {
        id
        email
        roles
      }
    }
  }
`;

export const destroyUser = gql`
  mutation destroyAdmin(
    $id: ID!
  ) {
    destroyAdmin(
      id: $id,
    ) {
      id
    }
  }
`;
