import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';
import { isAuthenticated } from '../helpers/auth';
import { currentUserVar } from '../helpers/roles';
import { showUser } from '../components/apiCalls/user';
import { useQuery } from '@apollo/client';
import { ToastContainer,  Slide } from 'react-toastify';

import loadable from '@loadable/component';
const AuthBasicLayout = loadable(() => import('./AuthBasicLayout'));
const WizardLayout = loadable(() => import('../components/auth/wizard/WizardLayout'));
const Landing = loadable(() => import('../components/landing/Landing'));

const ProtectedRoute = ({ component: Comp, destination, path, ...rest }) => {
  return (
    <Route
      path={path}
      {...rest}
      render={props => {
        return isAuthenticated() ? (
          <Comp {...props} />
        ) : (
          <Redirect
            to={{pathname: `/authentication/basic/login`}}
          />
        );
      }}
    />
  );
};

const Layout = () => {
  const { data } = useQuery(showUser, {
    skip: !isAuthenticated()
  });

  if (data) {
    const currentUser = currentUserVar();
    currentUserVar([...currentUser, data.admin]);
  }

  return (
    <>
      <Router fallback={<span />}>
        <Switch>
          <Route path="/" exact>
          {
            isAuthenticated() ? <Redirect to="/products" /> : <Landing />
          }
          </Route>
          <Route path="/authentication/basic" component={AuthBasicLayout} />
          <Route path="/acceptance/:supplier_id" component={WizardLayout} />
          <Route path="/errors" component={ErrorLayout} />
          <ProtectedRoute component={DashboardLayout} />
        </Switch>
      </Router>

      <ToastContainer
        position="bottom-left"
        transition={Slide}
        autoClose={5000}
      />
    </>
  );
};

export default Layout;
